<section class="MainCard"> 
    <!-- Tabs  -->
    <div class="TabsHolder">
        @if (fetchDepartmentsLoading) {
            <ul>
                <li class="Stolt-Skeleton" *ngFor="let number of [0,1,2,3,4,5,6,7]"></li>
            </ul>
        }
        @else {
            @for (tab of tabsData; track $index) {
                <snl-page-tab [isSelected]="tab?.department === selectedTab" (onPageTabClick)="onSelectTab(tab)">
                    <svg-icon class="df-c" src="assets/icons/{{tab?.actionLegend?.name}}.svg"></svg-icon>
                    {{departmentMapping[tab?.department]}}
                </snl-page-tab>
            }
        }
    </div>

    <div class="CardHolder">
        <!-- Left Card CCD Preview Details  -->
        <div class="InfoCardLftHolder">
        <app-preview-ccd-details [ownerAddedDocuments]="ownerAddedDocuments" [ccdRes]="ccdRes" [editVisibility]="editButtonVisibility()" [showEditButton]="accessOfEditButton()" [editButtonRoute]="editButtonRoute" [fetchDataLoading]="fetchDataLoading" [showApproveButton]="accessOfApproveButton()" [isManagementTabOpen]="isManagementTabOpen()" [disableApproveButton]="disableApproveButton"></app-preview-ccd-details>
        </div>
        
        <!-- Right Card CCD Departments’ notes  -->
         <div class="InfoCardRgtMain">
            <div class="InfoCardLft InfoCardRgt">
                <h2 *ngIf="selectedTab === 'OWNER'" class="sub-heading-s1">Departments’ notes</h2>
                <ul *ngIf="selectedTab === 'OWNER'" class="single-note">
                    @if (fetchCcdNotesLoading) {
                    <li class="Stolt-Skeleton" *ngFor="let number of [0,1,2,3,4,5,6]"></li>
                    }
                    @else if (isEmptyObject(ccdNotes)) {
                    <span class="body-b1">NA</span>
                    }
                    @else {
                    <li *ngIf="ccdNotes?.LEGAL?.notes">
                        <span class="body-b FieldTitle">Legal department</span>
                        @if (notesTooltipVisibility?.legalNote) {
                        <snl-tooltip [position]="'top'">
                            <span class="section-title truncate-text">{{ccdNotes?.LEGAL?.notes}}</span>
                            <div tooltipContent class="TpCustomization fixed240"> 
                                <div class="section-title">
                                    {{ccdNotes?.LEGAL?.notes}}
                                </div>
                            </div>
                        </snl-tooltip>
                        }
                        @else {
                        <span class="section-title truncate-text" id="legalNote">{{ccdNotes?.LEGAL?.notes}}</span>
                        }
                    </li>
                    <li *ngIf="ccdNotes?.ACCOUNTING?.notes">
                        <span class="body-b FieldTitle">Accounting department</span>
                        @if (notesTooltipVisibility?.accountingNote) {
                        <snl-tooltip [position]="'top'">
                            <span class="section-title truncate-text">{{ccdNotes?.ACCOUNTING?.notes}}</span>
                            <div tooltipContent class="TpCustomization fixed240"> 
                                <div class="section-title">
                                    {{ccdNotes?.ACCOUNTING?.notes}}
                                </div>
                            </div>
                        </snl-tooltip>
                        }
                        @else {
                        <span class="section-title truncate-text" id="accountingNote">{{ccdNotes?.ACCOUNTING?.notes}}</span>
                        }
                    </li>
                    <li *ngIf="ccdNotes?.OPERATIONS_MANAGER?.notes">
                        <span class="body-b FieldTitle">Operations manager</span>
                        @if (notesTooltipVisibility?.operationsManagerNote) {
                        <snl-tooltip [position]="'top'">
                            <span class="section-title truncate-text">{{ccdNotes?.OPERATIONS_MANAGER?.notes}}</span>
                            <div tooltipContent class="TpCustomization fixed240"> 
                                <div class="section-title">
                                    {{ccdNotes?.OPERATIONS_MANAGER?.notes}}
                                </div>
                            </div>
                        </snl-tooltip>
                        }
                        @else {
                        <span class="section-title truncate-text" id="operationsManagerNote">{{ccdNotes?.OPERATIONS_MANAGER?.notes}}</span>
                        }
                    </li>
                    <li *ngIf="ccdNotes?.TANKER?.notes">
                        <span class="body-b FieldTitle">Tanker controller</span>
                        @if (notesTooltipVisibility?.tankerControllerNote) {
                        <snl-tooltip [position]="'top'">
                            <span class="section-title truncate-text">{{ccdNotes?.TANKER?.notes}}</span>
                            <div tooltipContent class="TpCustomization fixed240"> 
                                <div class="section-title">
                                    {{ccdNotes?.TANKER?.notes}}
                                </div>
                            </div>
                        </snl-tooltip>
                    }
                    @else {
                    <span class="section-title truncate-text" id="tankerControllerNote">{{ccdNotes?.TANKER?.notes}}</span>
                    }
                    </li>
                    <li *ngIf="ccdNotes?.DEMURRAGE?.notes">
                        <span class="body-b FieldTitle">Demurrage</span>
                        @if (notesTooltipVisibility?.demurrageNote) {
                        <snl-tooltip [position]="'top'">
                            <span class="section-title truncate-text">{{ccdNotes?.DEMURRAGE?.notes}}</span>
                            <div tooltipContent class="TpCustomization fixed240"> 
                                <div class="section-title">
                                    {{ccdNotes?.DEMURRAGE?.notes}}
                                </div>
                            </div>
                        </snl-tooltip>
                        }
                        @else {
                        <span class="section-title truncate-text" id="demurrageNote">{{ccdNotes?.DEMURRAGE?.notes}}</span>
                        }
                    </li>
                    <li *ngIf="ccdNotes?.GROUP_RISK?.notes">
                        <span class="body-b FieldTitle">Group risk</span>
                        @if (notesTooltipVisibility?.groupRiskNote) {
                        <snl-tooltip [position]="'top'">
                            <span class="section-title truncate-text">{{ccdNotes?.GROUP_RISK?.notes}}</span>
                            <div tooltipContent class="TpCustomization fixed240"> 
                                <div class="section-title">
                                    {{ccdNotes?.GROUP_RISK?.notes}}
                                </div>
                            </div>
                        </snl-tooltip>
                        }
                        @else {
                        <span class="section-title truncate-text" id="groupRiskNote">{{ccdNotes?.GROUP_RISK?.notes}}</span>
                        }
                    </li>
                    <li *ngIf="ccdNotes?.MANAGEMENT?.notes">
                        <span class="body-b FieldTitle">Management approval</span>
                        @if (notesTooltipVisibility?.managementNote) {
                        <snl-tooltip [position]="'top'">
                            <span class="section-title truncate-text">{{ccdNotes?.MANAGEMENT?.notes}}</span>
                            <div tooltipContent class="TpCustomization fixed240"> 
                                <div class="section-title">
                                    {{ccdNotes?.MANAGEMENT?.notes}}
                                </div>
                            </div>
                        </snl-tooltip>
                        }
                        @else {
                        <span class="section-title truncate-text" id="managementNote">{{ccdNotes?.MANAGEMENT?.notes}}</span>
                        }
                    </li>
                    }
                </ul>

                @if(selectedTab !== 'OWNER') {
                    @if(accessOfUploadDocument()) {
                    <!-- Upload your documents here -->
                    <div class="HeadingMngr">
                        <h2 class="sub-heading-s1">Document(s)</h2>
                        <div class="DragDropFile">
                            <p class="body-b clr__secondary">Upload your documents here</p>
                            @if(refreshSnlComponent){
                            <app-upload-files [showMarkAsComplete]="showMarkAsComplete" [documents]="departmentDocuments" [documentLoading]="fetchCcdDocumentsLoading" [uploadScreen]="'review'" [ccdNo]="ccdNo" [department]="selectedTab"></app-upload-files>
                            }
                        </div>
                    </div>
                    }
                    @else {
                        <div class="HeadingMngr scroll">
                            <h2 class="sub-heading-s1">Document(s) by {{departmentMapping[selectedTab]}}</h2>
                            @if (fetchCcdDocumentsLoading) {
                                <div class="StoltSkeletonUpld">
                                    <div class="Stolt-Skeleton"></div>
                                    <div class="Stolt-Skeleton"></div>
                                </div>
                            }
                            @else if(departmentDocuments?.length > 0){
                                @for (doc of departmentDocuments; track $index) {
                                 <span class="body-b1 downloadItms">
                                    <span class="DocNameIn">{{uploadService.extractFileName(doc?.fileName)}}</span>
                                    @if (downloadingDocumentId === doc?.docId) {
                                    <svg-icon class="df-c rotating-icon" src="assets/icons/loader-icon.svg"></svg-icon>
                                    }
                                    @else{
                                    <svg-icon (click)="downloadFile(doc?.docId)" class="df-c" src="assets/icons/download.svg"></svg-icon>
                                    }
                                </span>
                                }
                            }
                            @else {
                                <p class="body-b">No document added yet.</p>}
                        </div> 
                    }
                }
            </div>

            <div class="InfoCardLft InfoCardRgt NotesHolder" *ngIf="selectedTab !== 'OWNER'">
                <h2 class="sub-heading-s1 maximizeIcn">Notes
                    <svg-icon (click)="openNotesPopupBox()" class="df-c" src="assets/icons/maximize.svg"></svg-icon>
                </h2>
                @if (refreshSnlComponent) {
                <app-notes [accessOfNotesEditor]="accessOfNotesEditor()" [selectedTab]="selectedTab" [ccdNo]="ccdNo" [from]="'review'"></app-notes>
                }
            </div>
        </div>

    </div>
</section>

<!-- Sticky Footer Bottom Action Panel -->
 @if (accessOfActionPanel()) {
<footer class="FixedFooterBar">
    <snl-action-panel>
        <div class="gap16 df-c" left-side>
            @if (autoSaveMessage === 'not-saved') {
            <span>Changes not saved</span>
            }
            @else if (autoSaveMessage === 'saving') {
            <span class="df-c MessageBtm"> 
                <svg-icon class="df-c rotating-icon" src="assets/icons/loader-icon.svg"></svg-icon>
                Saving ... 
            </span>
            }
            @else if (autoSaveMessage === 'saved') {
            <span class="df-c MessageBtm"> 
                <svg-icon class="df-c" src="assets/icons/check-icon.svg"></svg-icon> 
                Saved
            </span>
            }
        </div>
        <div class="df-c SwitchCstm" right-side>
            <span class="section-title">Mark as complete</span>
            <snl-switch *ngIf="refreshSnlComponent"
            class="LargeSwitch"
            [ngClass]="disableMarkAsComplete() ? 'disabled' : ''"
            [activeText]="'YES'"
            [inActiveText]="'NO'"
            [toggleState]="showMarkAsComplete"
            (toggle)="markAsComplete($event)"
            [disabled]="disableMarkAsComplete()"
          ></snl-switch>
        </div>
    </snl-action-panel>
</footer>
 }

 @if(openNotesPopup) {
 <app-notes-popup [accessOfNotesEditor]="accessOfNotesEditor()" [selectedTab]="selectedTab" [ccdNo]="ccdNo" [open]="openNotesPopup" (onButtonClick)="closeNotesPopup()"></app-notes-popup>
 }