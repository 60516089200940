
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { ActionPanelModule, ButtonModule, DatePickerModule, DropdownModule, InputModule, LinkModule, PageTitleModule } from 'snl-complib';
import { CcdService } from 'src/app/services/ccd.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { TYPEOFCOA } from 'src/app/constant/constant';
import { DEPARTMENTS } from 'src/app/constant/table-headings';
import { DeleteNewCcdCreationComponent } from 'src/app/components/delete-new-ccd-creation/delete-new-ccd-creation.component';
import { ValidationService } from 'src/app/services/validation.service';
import { forkJoin } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { customizedMessages, Toasts } from 'src/app/constant/toast.messages';
import { PreviewCcdDetailsComponent } from "../../components/preview-ccd-details/preview-ccd-details.component";

@Component({
  selector: 'app-create-ccd-step2',
  standalone: true,
  imports: [DeleteNewCcdCreationComponent, CommonModule, ReactiveFormsModule, SvgIconComponent, DropdownModule, DatePickerModule, InputModule, ButtonModule, ActionPanelModule, PageTitleModule, LinkModule, PreviewCcdDetailsComponent],
  templateUrl: './create-ccd-step2.component.html',
  styleUrls: ['./create-ccd-step2.component.scss']
})
export class CreateCcdStep2Component implements OnInit {
  public ccdRes: any = {};
  public ccdForm: FormGroup = new FormGroup({});
  public savedFormObj: any = [];
  public ownerAddedDocuments:any = [];
  private ccdNo: string | null = null;
  public typeOfCoa: any = TYPEOFCOA;
  public selectedCoaType: string = 'NEW_COA';
  public visibleFields: any[] = [];
  public openDeleteDraftPopup: boolean = false;
  public submitLoading: boolean = false;
  public saveExitLoading: boolean = false;
  public fetchDataLoading: boolean = false;
  public editButtonRoute: string = '';
  public autoSaveMessage: string = 'saved'; //not-saved, saved, saving

  constructor(
    private ccdService: CcdService,
    private toasterService: ToasterService,
    private validationService: ValidationService,
    private storageService: StorageService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.ccdNo = this.route.snapshot.queryParamMap.get('ccdNo');
    if (this.ccdNo) {
      this.fetchData();
      this.editButtonRoute = `/dashboard/create-ccd-step1?ccdNo=${this.ccdNo}`;
      //set header data
      let queryParamsString:string =`?ccdNo=${this.ccdNo}`;
      const headerData = {
        headerTitle: 'Create CCD',
        backButtonNavigateTo: `dashboard/create-ccd-step1${queryParamsString}`,
        breadCrumbList: [
          { id: '1', label: 'Dashboard', navigateTo:'dashboard',isClickable: true },
          { id: '3', label: 'Create CCD', isClickable: false }
        ]
      }
      this.navigationService.setHeaderData(headerData);
    }
  }

  fetchData() {
    const fetchCcdData$ = this.ccdService.fetchCcdData({ ccdNo: this.ccdNo });
    const fetchCcdNotes$ = this.ccdService.fetchCcdNotes({ ccdNo: this.ccdNo, department: 'OWNER' });

    this.fetchDataLoading = true;
    forkJoin([fetchCcdData$, fetchCcdNotes$]).subscribe({
      next: ([ccdData, ccdNotes]: any) => {
        this.ccdRes = ccdData?.ccdRes?.[0];
        this.ownerAddedDocuments = ccdData?.ownerAddedDocuments;
        if (this.ccdRes?.coaType === 'NEW_RATES_AND_TERMS' && this.ccdRes?.isNewClause === true) {
          this.selectedCoaType = 'NEW_RATES_AND_TERMS_WITH_NEW_CLAUSE';
        } else {
          this.selectedCoaType = this.ccdRes?.coaType;
        }
        this.makeCcdForm();
        this.populateCcdNotes(ccdNotes?.notes);
        this.fetchDataLoading = false;
      },
      error: (error) => {
        this.fetchDataLoading = false;
        this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
        console.error('Error fetching data:', error);
      }
    });
  }

  makeCcdForm() {
    const formControls: { [key: string]: FormControl } = {};

    // Filter fields based on selectedCoaType and add to form
    this.visibleFields = DEPARTMENTS.filter(field => field.visibleIn.includes(this.selectedCoaType));

    this.visibleFields.forEach(field => {
      formControls[field.fieldName] = new FormControl('', [Validators.minLength(3), Validators.maxLength(100)]);
    });
    Object.keys(formControls).forEach(field => {
      this.ccdForm.addControl(field, formControls[field]);
    });
  }

  populateCcdNotes(ccdNotesObj: any = {}) {
    Object.keys(ccdNotesObj).forEach(key => {
      if (this.ccdForm.get(key) && !this.ccdRes?.initiatedFrom) {
        this.ccdForm.get(key)?.patchValue(ccdNotesObj?.[key]?.notes);
      }
    });
    this.savedFormObj = this.ccdForm.value;
  }

  inputChanged(): void {
    const currentFormValues = this.ccdForm.value;
    const isFormChanged = JSON.stringify(this.savedFormObj) !== JSON.stringify(currentFormValues);

    if (isFormChanged) {
      this.autoSaveMessage = 'not-saved';
    } else {
      this.autoSaveMessage = 'saved';
    }
  }

  trimFormFields() {
    Object.keys(this.ccdForm.controls).forEach(key => {
      const control = this.ccdForm.get(key);
      if (control && typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  }

  showError(field: string) {
    let errorMsg = this.validationService.showInputError(field, this.ccdForm);
    return errorMsg;
  }

  autoSave(field: string) {
    this.trimFormFields();
    const fieldValue = this.ccdForm.get(field)?.value;
    if (this.ccdForm.valid && fieldValue !== '' && fieldValue !== this.savedFormObj[field]) {
      const reqData = {
        ccdNo: this.ccdNo,
        departments: this.ccdForm?.value
      };
      this.autoSaveMessage = 'saving';
      this.ccdService.createCcdStep2(reqData).subscribe({
        next: ({ createdContract }: any) => {
          this.ccdForm.get('ccdNo')?.patchValue(createdContract?.ccdNo);
          this.savedFormObj = this.ccdForm.value;
          this.autoSaveMessage = 'saved';
        },
        error: (error) => {
          this.autoSaveMessage = 'not-saved';
          console.error('Error creating/updating draft CCD:', error);
        }
      });
    }
  }

  saveAndExit() {
    this.trimFormFields();
    if (this.ccdForm.valid) {
      const reqData = {
        ccdNo: this.ccdNo,
        departments: this.ccdForm?.value
      };
      this.saveExitLoading = true;
      this.ccdService.createCcdStep2(reqData).subscribe({
        next: () => {
          this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.AutoSave.Success, Toasts.Actions.AutoSave.Title);
          this.storageService.putDataInStorage('selectedTab', 'draft');
          this.router.navigate(['/dashboard']);
          this.saveExitLoading = false;
        },
        error: (error) => {
          this.saveExitLoading = false
          this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.GeneralError.Error, Toasts.Actions.GeneralError.Title);
          console.error('Error updating draft CCD:', error);
        }
      });
    }
    else {
      this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.AutoSave.Success, Toasts.Actions.AutoSave.Title);
      this.storageService.putDataInStorage('selectedTab', 'draft');
      this.router.navigate(['/dashboard']);
    }
  }

  areAllRequiredFieldsFilled(): boolean {
    let allMandatoryFieldsFilled = false;
    if (this.ccdRes?.coaType === 'OPTIONAL_YEARS') {
      allMandatoryFieldsFilled = true;
    }
    if (this.visibleFields.length > 0) {
      allMandatoryFieldsFilled = this.visibleFields.every(field => {
        const control = this.ccdForm.get(field?.fieldName);
        return control && control.value && control.valid;
      });
    }

    return allMandatoryFieldsFilled && this.ccdForm.valid;
  }

  truncateString(value: string, limit: number): string {
    if (!value) return '';
    return value.length > limit ? value.substring(0, limit) + '...' : value;
  }

  onSubmit() {
    if (this.ccdForm.valid) {
      this.trimFormFields();
      const reqData = {
        ccdNo: this.ccdNo,
        departments: this.ccdForm?.value,
        submittedAt: this.ccdService.getLocalISOTime(new Date())
      };
      this.submitLoading = true;
      this.ccdService.submitCcd(reqData).subscribe({
        next: ({ ccdNo }: any) => {
          this.toasterService.openToastCcd(Toasts.Types.Success, customizedMessages('submit', ccdNo, this.truncateString(this.ccdRes?.customer, 20)), Toasts.Actions.Submit.Title);
          this.storageService.putDataInStorage('selectedTab', 'active');
          this.router.navigate(['/dashboard']);
          this.submitLoading = false;
        },
        error: (error) => {
          console.error('Error creating/updating CCD:', error);
          this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.Submit.Error);
          this.submitLoading = false;
        }
      });
    }
  }

  deleteCcdPopup() {
    this.openDeleteDraftPopup = true;
  }
  deleteCcd(event: any) {
    this.openDeleteDraftPopup = false;
    if (event?.isDeleteCcd) {
      if (this.ccdNo) {
        const reqData = {
          ccdNo: this.ccdNo,
          type: "discard",
          updateValue: ''
        }
        this.ccdService.updateContractStatus(reqData).subscribe({
          next: () => {
            this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.DeleteCcd.Success, Toasts.Actions.DeleteCcd.Title);
            this.router.navigate(['/dashboard']);
          },
          error: (error) => {
            console.error('Error deleting CCD:', error);
            this.toasterService.openToastCcd(Toasts.Types.Error, Toasts.Actions.DeleteCcd.Error);
          }
        });
      }
      else {
        this.toasterService.openToastCcd(Toasts.Types.Success, Toasts.Actions.DeleteCcd.Success, Toasts.Actions.DeleteCcd.Title);
        this.router.navigate(['/dashboard']);
      }
    }
  }
}