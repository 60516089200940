import { Injectable } from '@angular/core';
import { WindowRef } from './window-ref.service';
declare let ga:any; 
declare let gtag:any; 
@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsService {
  constructor(private _windowRef: WindowRef) { }

   
   public sendPageTrackingLog(arData: any) {
    let arPageTracing = {
        eventCategory: arData.eventCategory || '',
        userName: arData.user_name || '',
        eventAction:arData.eventAction || '',
        eventLabel:arData.eventLabel || '',
        eventValue:arData.eventValue || ''
    };
    this.addGoogleTagsLog(arPageTracing);
}

public addGoogleTagsLog(arData: any) {
    if (arData.eventCategory) {
        let arDataLayer = arData;
        this._windowRef.nativeWindow.dataLayer.push(arDataLayer);
    }
}
}





