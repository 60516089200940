<section class="DetailsWrapMain">
    <!-- Left Card CCD Preview Details  -->
    <div class="InfoCardLftHolder">
        <app-preview-ccd-details [ownerAddedDocuments]="ownerAddedDocuments" [ccdRes]="ccdRes" [editVisibility]="true" [showEditButton]="true" [editButtonRoute]="editButtonRoute" [fetchDataLoading]="fetchDataLoading"></app-preview-ccd-details>
    </div>
    
    <!-- Right Card CCD Departments’ notes  -->
    <div class="InfoCardLft InfoCardRgt">
        <h2 class="sub-heading-s1">Departments’ notes</h2>
        <p class="body-b SubHeadingBtm">Enter notes for all the departments</p>
        <form [formGroup]="ccdForm">
            <ul>
                @if (fetchDataLoading) {
                <li class="Stolt-Skeleton" *ngFor="let number of [0,1,2,3,4,5,6]"></li>
                }
                @else if(visibleFields && visibleFields.length > 0) {
                <li *ngFor="let field of visibleFields" [ngClass]="showError(field?.fieldName) ? 'ErrorSpaceBottom' : ''">
                    <span class="body-b2 FieldTitle">{{field?.header}}</span>
                    <snl-input [label]="'Notes'" [formControlName]="field?.fieldName" [errorMessage]="showError(field?.fieldName)" (focusout)="autoSave(field?.fieldName)" (onInputValueChange)="inputChanged()"></snl-input>
                </li>
                }
                @else {
                    <span class="body-b1">NA</span>
                }
            </ul>
        </form>   
    </div>
</section>

<!-- Sticky Footer Bottom Action Panel -->
<footer class="FixedFooterBar">
    <snl-action-panel>
        <div class="gap16 df-c" left-side>
            <snl-button class="BtnSkeleton" [ngClass]="{'Stolt-Skeleton': submitLoading}" [size]="'large'" (buttonClick)="onSubmit()" [disabled]="!areAllRequiredFieldsFilled()">Submit</snl-button>
            <snl-button class="BtnSkeleton" [ngClass]="{'Stolt-Skeleton': saveExitLoading}" [size]="'large'" [type]="'secondary'" (buttonClick)="saveAndExit()">Save & exit</snl-button>
            @if (autoSaveMessage === 'not-saved') {
            <span>Draft not saved</span>
            }
            @else if (autoSaveMessage === 'saving') {
            <span class="df-c MessageBtm"> 
                <svg-icon class="df-c rotating-icon" src="assets/icons/loader-icon.svg"></svg-icon>
                Saving draft 
            </span>
            }
            @else if (autoSaveMessage === 'saved') {
            <span class="df-c MessageBtm"> 
                <svg-icon class="df-c" src="assets/icons/check-icon.svg"></svg-icon> 
                Saved as draft
            </span>
            }
        </div>
        <div right-side>
            <snl-button [type]="'tertiary'" (buttonClick)="deleteCcdPopup()">Delete</snl-button>
        </div>
    </snl-action-panel>
</footer>
@if(openDeleteDraftPopup) {
    <app-delete-new-ccd-creation [open]="openDeleteDraftPopup" (onButtonClick)="deleteCcd($event)"></app-delete-new-ccd-creation>
}