import { Injectable } from '@angular/core';
import { VALIDATION_MESSAGE, customizedValidationMessages } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  validationMessages:any = VALIDATION_MESSAGE;
  
  public showInputError(field:string,form:any)
  {
    const control = form.get(field);
    if (control && control.errors) {
      for (const key in control.errors) {
        if (control.errors.hasOwnProperty(key)) {
          let errorMessage:string='';
          switch (key) {
            case 'minlength':
              errorMessage = customizedValidationMessages(key,control?.errors?.minlength?.requiredLength)
              break;
            case 'maxlength':
              errorMessage = customizedValidationMessages(key,control?.errors?.maxlength?.requiredLength)
              break;
            case 'pattern':
              if(['estAnnualVolumeMin', 'estAnnualVolumeMax', 'estAnnualFreightMin', 'estAnnualFreightMax'].includes(field)){
                errorMessage = this.validationMessages['onlyNumbers'];
              }
              break;
            case 'minMaxVolume':
              errorMessage = this.validationMessages['minMaxVolume'];
              break;
            case 'minMaxFreight':
              errorMessage = this.validationMessages['minMaxFreight'];
              break;
            default:
              errorMessage = this.validationMessages[key]
              break;
          }
          return errorMessage;
        }
      }
    }
    return '';
  }
}
