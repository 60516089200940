export const environment = {
  production: true,
  type: 'uat',
  BASE_URL: 'https://api.stoltuat.com/ccd-apis/',
  clientId: "ec6929d1-1202-46bb-8b9f-4b91439786ab",
  authority: "https://login.microsoftonline.com/a86e0b89-48f7-4a70-8e01-1962b219c224",
  redirectUri: "https://ccd-uat.stolttankers.com",
  GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
  BACKEND_URL: 'https://api.stoltuat.com/ccd-apis/',
  USERSCOPE: 'api://ceb8d932-c4ac-4c04-a1b8-4a2e11de1df2/.default',
  GOOGLE_ANALYTICS: {
    googleAnalyticKey: 'G-2L7HGVYYD1',
  },
};