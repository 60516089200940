import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';
import { CcdService } from './services/ccd.service';
import { filter, Subject, takeUntil } from 'rxjs';
import { StorageService } from './services/storage.service';
declare const gtag: Function;

type ProfileType = {
	givenName?: string,
	surname?: string,
	displayName?: string,
	userPrincipalName?: string,
	id?: string
};

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterModule],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
	title = 'ST Contract Control Document';
	loginDisplay = false;
	msalUserInfoFetched = false;
	private readonly _destroying$ = new Subject<void>();
	constructor(
		private broadcastService: MsalBroadcastService,
		private msalService: MsalService,
		private http: HttpClient,
		private authService: AuthService,
		private ccdService: CcdService,
		private storageService: StorageService
	) { this.addGAScript(); }

	ngOnInit() {
		this.broadcastService.inProgress$
			.pipe(
				filter((status: InteractionStatus) => status === InteractionStatus.None),
				takeUntil(this._destroying$)
			)
			.subscribe(() => {
				this.setLoginDisplay();
				this.getProfile();
			})
	}

	setLoginDisplay() {
		this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
	}

	getProfile() {
		this.http.get(environment.GRAPH_ENDPOINT)
			.subscribe((profile: ProfileType) => {
				this.authService.setUserData(profile);
				this.msalUserInfoFetched = true;
				//this.getMSALToken();
				this.fetchUser();
			});
	}

	fetchUser() {
		this.ccdService.fetchUser().subscribe(({ userData }: any) => {
			this.authService.setBackendUserData(userData?.[0] || []);
			const currentDate = new Date().toISOString().split('T')[0];
			if (this.storageService.getDataFromStorage('loggedIn') === 'false' ||
				!this.storageService.getDataFromStorage('loggedIn') ||
				this.storageService.getDataFromStorage('lastLoginDate') !== currentDate) {
				this.updateLogs();
			}
		});
	}

	updateLogs() {
		this.ccdService.updateAuditLogs({ type: 'login' }).subscribe((response) => {
			this.storageService.putDataInStorage('loggedIn', 'true');
			const currentDate = new Date().toISOString().split('T')[0];
			this.storageService.putDataInStorage('lastLoginDate', currentDate);
		});
	}

	getMSALToken() {
		this.msalService.acquireTokenSilent({
			account: this.msalService.instance.getAllAccounts()[0],
			scopes: [environment.USERSCOPE]//['user.read'],
		}).subscribe((response) => {
			this.authService.setAccessToken(response.accessToken);
			this.fetchUser();
		})
	}

	addGAScript() {
		let gmap: HTMLScriptElement = document.createElement('script');
		gmap.async = true;
		if (environment.GOOGLE_ANALYTICS.googleAnalyticKey) {
			let gtagScript: HTMLScriptElement = document.createElement('script');
			gtagScript.async = true;

			gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GOOGLE_ANALYTICS.googleAnalyticKey;
			document.head.prepend(gtagScript);
			let gUAtagScript: HTMLScriptElement = document.createElement('script');
			gUAtagScript.async = true;

			gtag('js', new Date());
			gtag('config', environment.GOOGLE_ANALYTICS.googleAnalyticKey);

		}
	}
}
